import errorCodes from './errorCodes'

function findError(error) {
    if (error?.message) {
        return error.message
    }

    return errorCodes?.[error.code] || 'Произошла ошибка'
}

export default findError
