import { useEffect } from 'react'
import { Box, ButtonBase, Avatar, Link } from '@mui/material'
import LogoSection from 'layout/MainLayout/LogoSection'
import ProfileSection from './ProfileSection'
import Notifications from './Notifications'
import { userProfile } from 'store/auth/authSlice'
import { useTheme } from '@mui/material/styles'
import { IconMenu2 } from '@tabler/icons'
import { useDispatch } from 'react-redux'
import TelegramIcon from '@mui/icons-material/Telegram'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import styles from './header.module.css'

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(userProfile())
    }, [dispatch])

    return (
        <>
            <Box className={styles.Header}>
                <Box component="span" sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, width: '100px' }}>
                    <LogoSection />
                </Box>

                <ButtonBase sx={{ borderRadius: '6px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={() => dispatch(handleLeftDrawerToggle())}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', flexGrow: 1 }}>
                <Box sx={{ ml: 8 }}>
                    <Link sx={{ display: 'block' }} href="https://t.me/saleplus_kz" target="_blank" rel="noreferrer">
                        <TelegramIcon sx={{ fill: '#673ab7', display: 'block' }} />
                    </Link>
                </Box>
                <Box sx={{ ml: 2, mr: 'auto' }}>
                    <Link
                        sx={{ display: 'block' }}
                        href="https://api.whatsapp.com/send?phone=77064223564&text=%D0%94%D0%BE%D0%B1%D1%80%D1%8B%D0%B9%20%D0%B4%D0%B5%D0%BD%D1%8C%2C%20%D0%BF%D0%B8%D1%88%D1%83%20%D0%B8%D0%B7%20%D0%BF%D0%B0%D0%BD%D0%B5%D0%BB%D0%B8%20%D1%83%D0%BF%D1%80%D0%B0%D0%B2%D0%BB%D0%B5%D0%BD%D0%B8%D1%8F%20SalePlus.kz"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <WhatsAppIcon sx={{ fill: '#673ab7', display: 'block' }} />
                    </Link>
                </Box>

                <Notifications />
                <ProfileSection />
            </Box>
        </>
    )
}

export default Header
