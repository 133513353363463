import { Box } from '@mui/material'

const UpdSidebar = () => (
    <Box sx={{ backgroundColor: '#e7f8eb    ', borderRadius: '8px', p: 3, fontSize: '15px', mt: 2 }}>
        <Box sx={{ fontWeight: 'bold', mb: 1 }}>Upd. 07.09</Box>
        <Box sx={{ borderBottom: '1px dashed #939393', pb: 1, mb: 1 }}>
            Мы добавили реферальную программу. Приглашайте новых пользователей в сервис SalePlus.kz и зарабатываете 5%
            от каждой оплаты, всех приглашенных пользователей.
        </Box>
    </Box>
)

export default UpdSidebar
