import { lazy } from 'react'

// project imports
import Loadable from 'ui-component/Loadable'
import MinimalLayout from 'layout/MinimalLayout'

const Register = Loadable(lazy(() => import('views/pages/authentication/Register')))
const Login = Loadable(lazy(() => import('views/pages/authentication/Login')))
const FrontPage = Loadable(lazy(() => import('views/pages/FrontPage')))

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        // {
        //     path: '/pages/login/login3',
        //     element: <AuthLogin3 />
        // },
        {
            path: '/pages/register',
            element: <Register />
        },
        {
            path: '/pages/login',
            element: <Login />
        },
        {
            path: '/',
            element: <FrontPage />
        }
    ]
}

export default AuthenticationRoutes
