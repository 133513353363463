import { useDispatch } from 'react-redux'
import { forwardRef } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { closeLeftDrawer } from 'store/general/generalSlice'

// material-ui
import { useTheme } from '@mui/material/styles'
import { ListItemButton, ListItemText, Typography } from '@mui/material'

// project imports
import config from 'config'

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //

const NavItem = ({ item, level }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const pathnameArray = pathname.split('/')

    // const matchesSM = useMediaQuery(theme.breakpoints.down('lg'))

    // const Icon = item.icon
    // const itemIcon = item?.icon ? <Icon stroke={1.5} size="1.3rem" /> : ''

    let itemTarget = '_self'

    if (item.target) {
        itemTarget = '_blank'
    }

    let listItemProps = {
        component: forwardRef((props, ref) => (
            <Link ref={ref} {...props} to={`${config.basename}${item.url}`} target={itemTarget} />
        ))
    }

    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget }
    }

    const itemHandler = async () => {
        dispatch(closeLeftDrawer())
    }

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            sx={{
                borderRadius: `5px`,
                mb: 0.5,
                alignItems: 'flex-start',
                backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                py: level > 1 ? 1 : 1.25,
                pl: `${level * 24}px`
            }}
            selected={item.id === pathname.split('/')[pathnameArray.length - 1]}
            onClick={() => itemHandler()}
        >
            {/* <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon> */}
            <ListItemText
                primary={
                    <Typography
                        // variant={customization.isOpen.findIndex((id) => id === item.id) > -1 ? 'h5' : 'body1'}
                        color="inherit"
                    >
                        {item.title}
                    </Typography>
                }
                secondary={
                    item.caption && (
                        <Typography
                            variant="caption"
                            sx={{ ...theme.typography.subMenuCaption }}
                            display="block"
                            gutterBottom
                        >
                            {item.caption}
                        </Typography>
                    )
                }
            />
        </ListItemButton>
    )
}

export default NavItem
