const service = {
    id: 'services',
    title: 'Сервисы',
    type: 'group',
    children: [
        {
            id: 'import-export',
            title: 'Загрузка/выгрузка таблицы',
            type: 'item',
            url: 'app/import-export',
            breadcrumbs: false
        },
        // {
        //     id: 'kaspi-statistics',
        //     title: 'Потенциал продаж товаров',
        //     type: 'item',
        //     url: 'app/kaspi-statistics',
        //     breadcrumbs: false
        // },
        {
            id: 'export-markets',
            title: 'Выгрузка товаров для маркетплейсов',
            type: 'item',
            url: 'app/export-markets',
            breadcrumbs: false
        },
        {
            id: 'affiliate',
            title: 'Реферальная система',
            type: 'item',
            url: 'app/affiliate',
            breadcrumbs: false
        }
    ]
}

export default service
