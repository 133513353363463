import { useTheme } from '@mui/material/styles'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { BrowserView, MobileView } from 'react-device-detect'
import { useDispatch } from 'react-redux'
import MenuList from './MenuList'
import UpdSidebar from './UpdSidebar'

import { Box, Drawer } from '@mui/material'

import { drawerWidth } from 'store/constant'

const Sidebar = ({ drawerOpen, handleLeftDrawerToggle, window }) => {
    const dispatch = useDispatch()

    const theme = useTheme()

    const drawer = (
        <>
            <BrowserView>
                <PerfectScrollbar
                    component="div"
                    style={{
                        height: '100vh',
                        paddingLeft: '16px',
                        paddingRight: '16px'
                    }}
                >
                    <MenuList />

                    <UpdSidebar />
                </PerfectScrollbar>
            </BrowserView>
            <MobileView>
                <Box sx={{ px: 2 }}>
                    <MenuList />
                    <UpdSidebar />
                </Box>
            </MobileView>
        </>
    )

    const container = window !== undefined ? () => window.document.body : undefined

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, width: 'auto' }} aria-label="mailbox folders">
            <Drawer
                container={container}
                variant="temporary"
                anchor="left"
                open={drawerOpen}
                onClose={() => dispatch(handleLeftDrawerToggle())}
                sx={{
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        background: theme.palette.background.default,
                        color: theme.palette.text.primary,
                        borderRight: 'none'
                    }
                }}
                ModalProps={{ keepMounted: true }}
                color="inherit"
            >
                {drawer}
            </Drawer>
        </Box>
    )
}

export default Sidebar
