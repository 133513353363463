import { forwardRef } from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'

import { useSelector, useDispatch } from 'react-redux'
import { removeNotification } from 'store/general/generalSlice'

let Alert = (props, ref) => <MuiAlert ref={ref} variant="filled" {...props} />

Alert = forwardRef(Alert)

const Notification = () => {
    const dispatch = useDispatch()
    const notification = useSelector((state) => state.general.notification)

    const handleClose = () => {
        dispatch(removeNotification())
        // if (reason === 'clickaway') {
        //     dispatch(removeNotification())
        // }
    }

    return (
        <Snackbar
            open={notification.open}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert
                onClose={handleClose}
                variant="filled"
                color={notification.type}
                sx={{ width: '100%', maxWidth: '300px', '&.MuiAlert-filledSuccess': { backgroundColor: 'green' } }}
            >
                {notification.text}
            </Alert>
        </Snackbar>
    )
}

export default Notification
