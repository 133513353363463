import { createSlice } from '@reduxjs/toolkit'

export const generalSlice = createSlice({
    name: 'general',
    initialState: {
        leftDrawerOpened: false,
        notification: {}
    },
    reducers: {
        handleLeftDrawerToggle: (state) => {
            state.leftDrawerOpened = !state.leftDrawerOpened
        },
        closeLeftDrawer: (state) => {
            state.leftDrawerOpened = false
        },
        addNotification: (state, action) => {
            state.notification = {
                ...action.payload
            }
        },
        removeNotification: (state) => {
            state.notification = {
                open: false,
                message: '',
                type: ''
            }
        }
    }
})

export const selectGeneral = (state) => state.general

export const leftDrawerOpenState = (state) => state.general.leftDrawerOpen

export const { handleLeftDrawerToggle, closeLeftDrawer, addNotification, removeNotification } = generalSlice.actions

export default generalSlice.reducer
