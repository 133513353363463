import { useState, useRef, useEffect } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import { Box, ClickAwayListener, Paper, Popper } from '@mui/material'

import MainCard from 'ui-component/cards/MainCard'
import Transitions from 'ui-component/extend/Transitions'

import { IconBell, IconX } from '@tabler/icons'

import apiFetch from 'api/apiFetch'
import moment from 'moment-timezone'

const Notifications = () => {
    const anchorRef = useRef(null)
    const [open, setOpen] = useState(false)
    const [countNotifications, setCountNotifications] = useState(0)
    const [notifications, setNotifications] = useState({
        loading: false,
        list: [],
        firstOpen: true
    })

    const theme = useTheme()

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    const closeNotification = async (id) => {
        await apiFetch.put(`v1/notificationClose/${id}`, {})

        const modifyNotifictions = notifications.list.filter((notification) => notification.id !== id)
        setNotifications({
            ...notifications,
            list: modifyNotifictions
        })
    }

    const handleToggle = async () => {
        setOpen((prevOpen) => !prevOpen)

        if (!open && notifications.firstOpen) {
            try {
                setNotifications({
                    ...notifications,
                    loading: true
                })

                const list = await apiFetch.post('v1/notificationList', {
                    isRead: true
                })

                setCountNotifications(0)

                setNotifications({
                    list: list.data.data,
                    firstOpen: false,
                    loading: false
                })
            } catch {
                setNotifications({
                    ...notifications,
                    loading: false
                })
            }
        }
    }

    const getCountNotifications = async () => {
        const count = await apiFetch.get('v1/notificationCount', {})
        setCountNotifications(count.data.data)
    }

    useEffect(() => {
        try {
            getCountNotifications()
        } catch {
            setCountNotifications(0)
        }
    }, [])

    return (
        <>
            <Box
                ref={anchorRef}
                onClick={handleToggle}
                sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', mr: '20px', position: 'relative' }}
            >
                <IconBell sx={{ display: 'block', height: '25px', width: '25px' }} />

                {countNotifications > 0 ? (
                    <Box
                        sx={{
                            fontSize: '13px',
                            backgroundColor: 'red',
                            height: '20px',
                            width: '20px',
                            borderRadius: '6px',
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'absolute',
                            top: '-10px',
                            right: '-8px'
                        }}
                    >
                        {countNotifications}
                    </Box>
                ) : (
                    ''
                )}
            </Box>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    boxShadow
                                    shadow={theme.shadows[16]}
                                >
                                    <Box
                                        sx={{
                                            height: '100%',
                                            width: '320px',
                                            maxHeight: '450px',
                                            overflowX: 'hidden',
                                            overflowY: 'auto'
                                        }}
                                    >
                                        <Box sx={{ p: 2 }}>
                                            {notifications.list.length > 0 ? (
                                                notifications.list.map((notification) => (
                                                    <Box
                                                        key={notification.id}
                                                        sx={{
                                                            p: '8px 15px',
                                                            backgroundColor: '#f5f1fb',
                                                            borderRadius: '8px',
                                                            mb: '10px',
                                                            display: 'flex'
                                                        }}
                                                    >
                                                        <Box sx={{ mr: '10px', fontSize: '15px', lineHeight: '1.4' }}>
                                                            <Box sx={{ fontWeight: '600', mb: 1, fontSize: '13px' }}>
                                                                {moment(notification.created_at)
                                                                    .local()
                                                                    .format('DD.MM.YYYY HH:mm')}
                                                            </Box>
                                                            <Box>{notification.text}</Box>
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                height: '20px',
                                                                minWidth: '20px',
                                                                width: '20px',
                                                                ml: 'auto',
                                                                cursor: 'pointer',
                                                                backgroundColor: '#673ab6',
                                                                borderRadius: '5px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center'
                                                            }}
                                                        >
                                                            <IconX
                                                                style={{
                                                                    color: '#fff',
                                                                    display: 'block',
                                                                    width: '16px',
                                                                    height: '16px'
                                                                }}
                                                                onClick={() => closeNotification(notification.id)}
                                                            />
                                                        </Box>
                                                    </Box>
                                                ))
                                            ) : (
                                                <Box>Сообщений нет</Box>
                                            )}
                                        </Box>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    )
}

export default Notifications
