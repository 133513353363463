const errorCodes = {
    SERVER_ERROR: 'Ошибка сервера',
    EXTERNAL: 'Ошибка внешней службы',
    NO_ARGUMENT: 'Не указаны обязательные данные',
    BAD_REQUEST: 'Не заполнены данные',
    VALIDATION: 'Неправильные данные',
    INVALID_CREDENTIALS: 'Неверные учетные данные',
    WRONG_EMAIL_CONFIRM_TOKEN: 'Неправильный email ключ либо данным ключом уже воспользовались',
    EMAIL_ALREADY_TAKEN_ERROR: 'Данный email уже используется',
    EMAIL_TOKEN_EXPIRED: 'Время подтверждения email закончилось',
    USERNAME_ALREADY_TAKEN: 'Это имя уже занято, попробуйте использовать другое',
    LOGIN_NOT_FOUND: 'Пользовтель с таким email и паролем не найден'
}

export default errorCodes
