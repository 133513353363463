import axios from 'axios'
import findError from 'utils/findError'
import { addNotification } from 'store/general/generalSlice'

let store

export const injectStore = (_store) => {
    store = _store
}

const apiFetch = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api/`,
    withCredentials: true,
    'Content-Type': 'application/json'
})

apiFetch.interceptors.response.use(
    (response) => response,
    (error) => {
        store.dispatch(
            addNotification({
                open: true,
                type: 'error',
                text: findError(error.response.data)
            })
        )

        if (error.response.headers?.['x-session']) {
            localStorage.removeItem('userData')
            window.location.href = '/pages/login'
        }

        return Promise.reject(error)
    }
)

export default apiFetch
