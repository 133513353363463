import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import apiFetch from 'api/apiFetch'

export const login = createAsyncThunk('auth/login', async (credentials, thunkAPI) => {
    try {
        const response = await apiFetch.post('v1/auth', credentials)
        return response.data
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
})

export const userProfile = createAsyncThunk('user/profile', async (_, thunkAPI) => {
    try {
        const response = await apiFetch.get('v1/userProfile', {})
        return response.data
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
})

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
    try {
        const response = await apiFetch.post('v1/logout', {})
        localStorage.removeItem('userData')
        return response.data
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
})

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: {},
        loading: false,
        error: {}
    },
    extraReducers: (builder) => {
        builder.addCase(logout.fulfilled, (state) => {
            state.user = {}
            state.error = {}
        })
        builder.addCase(logout.rejected, (_, action) => ({
            user: {},
            error: action.payload
        }))
        builder.addCase(userProfile.pending, (state) => {
            state.loading = true
        })
        builder.addCase(userProfile.fulfilled, (state, action) => {
            state.user = action.payload.data.user
            state.loading = false
            state.error = {}
        })
        builder.addCase(userProfile.rejected, (_, action) => ({
            user: {},
            error: action.payload,
            loading: false
        }))
    }
})

export default authSlice.reducer
