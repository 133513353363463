const isAuth = () => {
    const userDataFromLocalStorage = JSON.parse(localStorage.getItem('userData'))

    if (userDataFromLocalStorage?.id) {
        return true
    }

    return false
}

export default isAuth
