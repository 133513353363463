import { Box } from '@mui/material'
import TelegramIcon from '@mui/icons-material/Telegram'

const Footer = () => (
    <Box sx={{ flexGrow: '1', padding: '0 20px 20px 20px' }}>
        <Box sx={{ maxWidth: '1300px', margin: '0 auto' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Box sx={{ color: '#767575' }}>© 2022 SalePlus.kz</Box>
                <Box sx={{ ml: 8 }}>
                    <a href="https://t.me/saleplus_kz" target="_blank" rel="noreferrer">
                        <TelegramIcon sx={{ fill: '#767575' }} />
                    </a>
                </Box>
            </Box>
        </Box>
    </Box>
)

export default Footer
