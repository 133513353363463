import store from './store'
import service from './service'

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [store, service]
}

export default menuItems
