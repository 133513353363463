import { useState, useRef, useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

// material-ui
import { useTheme } from '@mui/material/styles'
import {
    Box,
    ClickAwayListener,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography
} from '@mui/material'

// project imports
import MainCard from 'ui-component/cards/MainCard'
import Transitions from 'ui-component/extend/Transitions'

// assets
import { IconLogout, IconBrandGravatar } from '@tabler/icons'

import { logout } from 'store/auth/authSlice'
import { useNavigate } from 'react-router-dom'

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const theme = useTheme()
    const user = useSelector((state) => state.auth.user)
    // const navigate = useNavigate()

    const [selectedIndex] = useState(-1)
    const [open, setOpen] = useState(false)
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null)

    const handleLogout = async () => dispatch(logout()).then(() => navigate('/pages/login'))

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return
        }
        setOpen(false)
    }

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const prevOpen = useRef(open)

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus()
        }

        prevOpen.current = open
    }, [open])

    return (
        <>
            <Box
                ref={anchorRef}
                onClick={handleToggle}
                sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconBrandGravatar />
                </Box>
            </Box>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    boxShadow
                                    shadow={theme.shadows[16]}
                                >
                                    <Box sx={{ p: 2, width: '250px', maxWidth: '250px' }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4">Привет,</Typography>
                                                <Typography component="span" variant="h4" sx={{ fontWeight: 500 }}>
                                                    {user.name}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>

                                    <Box sx={{ p: 2, pt: 0 }}>
                                        <List
                                            component="nav"
                                            sx={{
                                                width: '100%',
                                                maxWidth: 350,
                                                minWidth: 250,
                                                backgroundColor: theme.palette.background.paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]: {
                                                    minWidth: '100%'
                                                },
                                                '& .MuiListItemButton-root': {
                                                    mt: 0.5
                                                }
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{ borderRadius: `10px` }}
                                                selected={selectedIndex === 4}
                                                onClick={handleLogout}
                                            >
                                                <ListItemIcon>
                                                    <IconLogout stroke={1.5} size="1.3rem" />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={<Typography variant="body2">Выйти</Typography>}
                                                />
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    )
}

export default ProfileSection
