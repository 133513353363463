import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import apiFetch from 'api/apiFetch'
import { addNotification } from 'store/general/generalSlice'

export const updateStore = createAsyncThunk('store/update', async (credentials, thunkAPI) => {
    try {
        const response = await apiFetch.put(`v1/store/${credentials.id}`, credentials.params)
        thunkAPI.dispatch(
            addNotification({
                open: true,
                type: 'success',
                text: 'Настройки обновлены'
            })
        )

        return {
            newStore: response.data.data,
            updateParams: credentials.params
        }
    } catch (err) {
        thunkAPI.dispatch(
            addNotification({
                open: true,
                type: 'error',
                text: 'Произошла ошибка'
            })
        )
        return thunkAPI.rejectWithValue(err.response.data)
    }
})

export const getStores = createAsyncThunk('store/list', async (credentials, thunkAPI) => {
    try {
        const response = await apiFetch.get(`v1/storeList`, {
            params: credentials
        })
        return response.data
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
})

export const storeSlice = createSlice({
    name: 'store',
    initialState: {
        stores: [],
        totalCount: 0,
        loading: false
    },
    extraReducers: (builder) => {
        builder.addCase(updateStore.fulfilled, (state, action) => {
            const storeIndex = state.stores.findIndex((store) => store.id === action.payload.newStore.id)
            state.stores[storeIndex] = {
                ...state.stores[storeIndex],
                ...action.payload.updateParams
            }
            state.error = {}
        })
        builder.addCase(updateStore.rejected, (_, action) => ({
            error: action.payload
        }))
        builder.addCase(getStores.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getStores.fulfilled, (state, action) => {
            state.stores = action.payload.data.stores
            state.totalCount = action.payload.data.totalCount
            state.loading = false
            state.error = {}
        })
        builder.addCase(getStores.rejected, (_, action) => ({
            error: action.payload,
            loading: false
        }))
    }
})

export default storeSlice.reducer
