const store = {
    id: 'stores',
    title: 'Основное',
    type: 'group',
    children: [
        {
            id: 'all',
            title: 'Мои магазины',
            type: 'item',
            url: 'app/stores/all',

            breadcrumbs: false
        },
        {
            id: 'add',
            title: 'Добавить магазин',
            type: 'item',
            url: 'app/stores/add',

            breadcrumbs: false
        },
        // {
        //     id: 'products',
        //     title: 'Все товары',
        //     type: 'item',
        //     url: 'app/stores/products',

        //     breadcrumbs: false
        // },
        {
            id: 'analytics',
            title: 'Аналитика заказов',
            type: 'item',
            url: 'app/analytics',

            breadcrumbs: false
        },
        {
            id: 'payment',
            title: 'Оплатить',
            type: 'item',
            url: 'app/payment',

            breadcrumbs: false
        }
    ]
}

export default store
