import { Outlet, Navigate } from 'react-router-dom'
import isAuth from 'utils/isAuth'

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => {
    if (isAuth()) {
        return <Navigate to="/app" />
    }

    return (
        <>
            <Outlet />
        </>
    )
}

export default MinimalLayout
