import { lazy } from 'react'

import MainLayout from 'layout/MainLayout'
import Loadable from 'ui-component/Loadable'

const DashboardDefault = Loadable(lazy(() => import('views/Dashboard/DashboardDefault')))
const DashboardProductList = Loadable(lazy(() => import('views/Dashboard/ProductList')))
const DashboardStores = Loadable(lazy(() => import('views/Dashboard/Stores')))
const DashboardStoreSettings = Loadable(lazy(() => import('views/Dashboard/StoreSettings')))
const DashboardAddStore = Loadable(lazy(() => import('views/Dashboard/AddStore')))
const DashboardNearestStores = Loadable(lazy(() => import('views/Dashboard/NearestStores')))
const DashboardCheckProductsCounter = Loadable(lazy(() => import('views/Dashboard/CheckProductsCounter')))
const DashboardImportExport = Loadable(lazy(() => import('views/Dashboard/ImportExport')))
const KaspiStatistics = Loadable(lazy(() => import('views/Dashboard/KaspiStatistics')))
const ExportMarket = Loadable(lazy(() => import('views/Dashboard/ExportMarket')))
const DashboardMainOrdersAnalytics = Loadable(lazy(() => import('views/Dashboard/OrdersAnalytics/mainPage')))
const NotFound = Loadable(lazy(() => import('views/NotFound')))
const StoreAuth = Loadable(lazy(() => import('views/Dashboard/StoreAuth')))
const Payment = Loadable(lazy(() => import('views/Dashboard/Payment')))
const DashboardAdminOrders = Loadable(lazy(() => import('views/Dashboard/AdminOrders')))
const AffiliateSystem = Loadable(lazy(() => import('views/Dashboard/AffiliateSystem')))

const MainRoutes = {
    path: '/app',
    element: <MainLayout />,
    children: [
        {
            path: '/app',
            element: <DashboardDefault />
        },
        {
            path: 'stores/all',
            element: <DashboardStores />
        },
        {
            path: 'stores/add',
            element: <DashboardAddStore />
        },
        // {
        //     path: 'stores/products',
        //     element: <DashboardProductList />
        // },
        {
            path: 'store/:storeId',
            element: <DashboardStoreSettings />
        },
        {
            path: 'store/products/:storeId',
            element: <DashboardProductList />
        },
        {
            path: 'analytics',
            element: <DashboardMainOrdersAnalytics />
        },
        {
            path: 'import-export',
            element: <DashboardImportExport />
        },
        {
            path: 'kaspi-statistics',
            element: <KaspiStatistics />
        },
        {
            path: 'stores/nearest',
            element: <DashboardNearestStores />
        },
        {
            path: 'stores/admin-orders',
            element: <DashboardAdminOrders />
        },
        {
            path: 'stores/check',
            element: <DashboardCheckProductsCounter />
        },
        {
            path: 'export-markets',
            element: <ExportMarket />
        },
        {
            path: 'store/auth/:storeId',
            element: <StoreAuth />
        },
        {
            path: 'payment',
            element: <Payment />
        },
        {
            path: 'affiliate',
            element: <AffiliateSystem />
        },

        {
            path: '*',
            element: <NotFound />
        }
    ]
}

export default MainRoutes
