import { configureStore } from '@reduxjs/toolkit'
import { generalSlice } from './general/generalSlice'
import { authSlice } from './auth/authSlice'
import { storeSlice } from './store/storeSlice'
import { productsSlice } from './products/productsSlice'

const reducer = {
    general: generalSlice.reducer,
    auth: authSlice.reducer,
    store: storeSlice.reducer,
    products: productsSlice.reducer
}

export function makeStore() {
    return configureStore({
        reducer
    })
}

const store = makeStore()

export default store
