import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit'
import apiFetch from 'api/apiFetch'

export const updateMinPrice = createAsyncThunk('product/price', async (credentials, thunkAPI) => {
    try {
        await apiFetch.put(`v1/product/${credentials.cityId}`, {
            minPrice: credentials.minPrice
        })

        return {
            cityId: credentials.cityId,
            minPrice: credentials.minPrice,
            productId: credentials.productId
        }
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
})

export const updateStep = createAsyncThunk('product/step', async (credentials, thunkAPI) => {
    try {
        await apiFetch.put(`v1/product/${credentials.cityId}`, {
            step: credentials.step
        })

        return {
            cityId: credentials.cityId,
            step: credentials.step,
            productId: credentials.productId
        }
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
})

export const updateMaxPrice = createAsyncThunk('product/max_price', async (credentials, thunkAPI) => {
    try {
        await apiFetch.put(`v1/product/${credentials.cityId}`, {
            maxPrice: credentials.maxPrice
        })

        return {
            cityId: credentials.cityId,
            maxPrice: credentials.maxPrice,
            productId: credentials.productId
        }
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
})

export const updateRemainder = createAsyncThunk('product/remainder', async (credentials, thunkAPI) => {
    try {
        await apiFetch.post(`v1/product/remainder`, {
            cityProductId: credentials.cityProductId,
            pickupPointNameId: credentials.pickupPointNameId,
            value: credentials.value
        })

        return {
            cityProductId: credentials.cityProductId,
            productId: credentials.productId,
            pickupPointNameId: credentials.pickupPointNameId,
            value: credentials.value
        }
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
})

export const getProducts = createAsyncThunk('products/list', async (credentials, thunkAPI) => {
    try {
        const response = await apiFetch.get(`v1/productList`, { params: credentials })
        return response.data
    } catch (err) {
        return thunkAPI.rejectWithValue(err.response.data)
    }
})

export const productsSlice = createSlice({
    name: 'products',
    initialState: {
        products: [],
        step: 0,
        totalCount: 0,
        counter: {},
        loading: false
    },
    extraReducers: (builder) => {
        builder.addCase(updateMinPrice.fulfilled, (state, action) => {
            const { cityId, minPrice, productId } = action.payload
            const productIndex = current(state.products).findIndex((product) => product.id === productId)
            const cityProductIndex = current(state.products)[productIndex].CityProduct.findIndex(
                (cityProduct) => cityProduct.id === cityId
            )

            state.products[productIndex].CityProduct[cityProductIndex].min_price = Number(minPrice)
        })

        builder.addCase(updateMaxPrice.fulfilled, (state, action) => {
            const { cityId, maxPrice, productId } = action.payload
            const productIndex = current(state.products).findIndex((product) => product.id === productId)
            const cityProductIndex = current(state.products)[productIndex].CityProduct.findIndex(
                (cityProduct) => cityProduct.id === cityId
            )

            state.products[productIndex].CityProduct[cityProductIndex].max_price = Number(maxPrice)
        })
        builder.addCase(updateStep.fulfilled, (state, action) => {
            const { cityId, step, productId } = action.payload
            const productIndex = current(state.products).findIndex((product) => product.id === productId)
            const cityProductIndex = current(state.products)[productIndex].CityProduct.findIndex(
                (cityProduct) => cityProduct.id === cityId
            )

            state.products[productIndex].CityProduct[cityProductIndex].step = Number(step)
        })
        builder.addCase(updateRemainder.fulfilled, (state, action) => {
            const { cityProductId, pickupPointNameId, productId, value } = action.payload

            const productIndex = current(state.products).findIndex((product) => product.product_id === productId)
            const cityProductIndex = current(state.products)[productIndex].CityProduct.findIndex(
                (cityProduct) => cityProduct.city_product_id === cityProductId
            )

            const PickupPointIndex = current(state.products)[productIndex].CityProduct[
                cityProductIndex
            ].PickupPoint.findIndex(
                (PickupPoint) =>
                    PickupPoint.pickup_point_name_id === pickupPointNameId &&
                    PickupPoint.pickup_point_city_product_id === cityProductId
            )

            state.products[productIndex].CityProduct[cityProductIndex].PickupPoint[PickupPointIndex].remainder = +value
        })
        builder.addCase(getProducts.pending, (state) => {
            state.loading = true
        })
        builder.addCase(getProducts.fulfilled, (state, action) => {
            state.products = action.payload.data.products
            state.totalCount = action.payload.data.totalCount
            state.loading = false
            state.counter = action.payload.data.counter
            state.step = action.payload.data.step
            state.error = {}
        })
        builder.addCase(getProducts.rejected, (_, action) => ({
            error: action.payload,
            loading: false
        }))
    }
})

export default productsSlice.reducer
