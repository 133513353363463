import { AppBar, Box, CssBaseline, Toolbar } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Sidebar from './Sidebar'
import { Outlet } from 'react-router-dom'
import Header from './Header'
import Footer from './Footer'

import style from './mainLayout.module.css'

import { handleLeftDrawerToggle } from 'store/general/generalSlice'
import { useSelector } from 'react-redux'

const MainLayout = () => {
    const theme = useTheme()
    const leftDrawerOpened = useSelector((state) => state.general.leftDrawerOpened)

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <CssBaseline />
            <AppBar
                // enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default,
                    transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
                    boxShadow: '0 4px 20px rgb(0 0 0 / 7%)'
                }}
            >
                <Toolbar>
                    <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
                </Toolbar>
            </AppBar>

            {/* drawer */}
            <Sidebar drawerOpen={leftDrawerOpened} handleLeftDrawerToggle={handleLeftDrawerToggle} />

            {/* main content */}
            <div className={style.Main}>
                <div className={style.Container}>
                    {/* <Box sx={{ backgroundColor: '#fcc7c6', borderRadius: '10px', p: '15px', mb: '25px' }}>
                        Спасибо за ожидание. Все проблемы ушли. У кого до сих пор не работает бот, пожалуйста, напишите
                        нам в whatsapp и мы переведем ваш магазин на новый алгоритм Каспи.
                    </Box> */}
                    <Outlet />
                </div>
            </div>

            <Footer />

            {/* <Customization /> */}
        </Box>
    )
}

export default MainLayout
