import { useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline, StyledEngineProvider } from '@mui/material'
import Routes from './routes'

import Notification from 'components/Notification'

import themes from 'theme'

const App = () => {
    useEffect(() => {
        document.title = 'SalePlus Kaspi'
    }, [])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes()}>
                <CssBaseline />
                <Routes />
                <Notification />
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

export default App
